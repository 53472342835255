import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filter, map } from 'rxjs';
import { AsyncPipe, NgClass } from '@angular/common';
import {
  AuthenticationService,
  NotificationCounterService,
} from '@shared/services';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'mlk-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, RouterLink, RouterLinkActive, NgClass],
})
export class MenuComponent {
  /** Injection of {@link Router}. */
  private readonly router = inject(Router);

  /** Injection of {@link NotificationCounterService}. */
  private readonly notificationCounterService = inject(
    NotificationCounterService
  );

  /** Injection of {@link AuthenticationService}. */
  private readonly authenticationService = inject(AuthenticationService);

  readonly isAuthenticated = this.authenticationService.isAuthenticated;

  readonly user$ = this.authenticationService.isAuthenticated$.pipe(
    filter(Boolean),
    map(() => this.authenticationService.getCurrentUser())
  );

  readonly nbrNotifications =
    this.notificationCounterService.notificationUnread;

  onLogout(): void {
    this.authenticationService.logout();
    this.router.navigateByUrl('/');
  }

  onProfile(): void {
    this.router.navigateByUrl('/mlk/profile');
  }

  closeMenu() {
    const navbar = document.querySelector('.navbar-collapse') as HTMLElement;
    if (navbar.classList.contains('show')) {
      const bsCollapse = new bootstrap.Collapse(navbar, {
        toggle: false,
      });
      bsCollapse.hide();
    }
  }
}
