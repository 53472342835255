<nav class="navbar shadow-sm navbar-expand-lg bg-white bg-light sticky-top">
  <div class="container">
    <div class="navbar-brand d-flex gap-5">
      <a routerLink="{{ isAuthenticated() ? '/mlk/home' : '/' }}"
        ><img src="assets/logo-mlk.png" style="width: 120px"
      /></a>
      @if (isAuthenticated()) {
        <ul
          class="navbar-nav d-md-none w-100 d-flex flex-row gap-3 justify-content-center">
          <li
            ngbDropdown
            class="nav-item position-relative"
            ngbTooltip="Mes notifications">
            <a
              [routerLink]="['/mlk/notifications']"
              routerLinkActive
              #notif="routerLinkActive"
              class="nav-link mlk-menu-link mlk-menu-link-icon">
              <i
                class="bi mlk-text-dark-blue"
                [ngClass]="{
                  'bi-bell': !notif.isActive,
                  'bi-bell-fill': notif.isActive,
                }"></i>
            </a>
            @if (nbrNotifications() > 0) {
              <div class="mlk-notifications-indicator"></div>
            }
          </li>
          <li ngbDropdown class="nav-item" ngbTooltip="Mon profil">
            <a
              href
              [routerLink]="['/mlk/profile']"
              routerLinkActive
              #profile="routerLinkActive"
              class="nav-link mlk-menu-link mlk-menu-link-icon">
              <i
                class="bi mlk-text-dark-blue"
                [ngClass]="{
                  'bi-person': !profile.isActive,
                  'bi-person-fill': profile.isActive,
                }"></i>
            </a>
          </li>
          <li ngbDropdown class="nav-item" ngbTooltip="Me déconnecter">
            <a
              href
              (click)="onLogout()"
              class="nav-link mlk-menu-link mlk-menu-link-icon">
              <i class="bi bi-power mlk-text-dark-blue"></i>
            </a>
          </li>
        </ul>
      }
    </div>
    <div
      class="d-flex d-lg-none justify-content-center align-items-center navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#mlk-menu"
      aria-controls="mlk-menu"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <i class="bi bi-list mlk-navbar-toggler-icon"></i>
    </div>
    <div class="collapse navbar-collapse navbar-expand-md" id="mlk-menu">
      @if (isAuthenticated()) {
        <div
          class="w-100 d-flex mt-md-3 justify-content-sm-around justify-content-lg-start">
          <ul
            class="navbar-nav w-100 my-3 mt-md-0 d-flex flex-column flex-md-row gap-3 gap-md-0 justify-content-md-start justify-content-lg-center">
            <li class="nav-item">
              <a
                class="nav-link mlk-menu-link"
                [routerLink]="['/mlk/home']"
                routerLinkActive="mlk-menu-link-active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="closeMenu()">
                ACCUEIL
              </a>
            </li>
            @if (user$ | async; as user) {
              @if (user.is_staff === true) {
                <li class="nav-item">
                  <a
                    class="nav-link mlk-menu-link"
                    [routerLink]="['/mlk/embassies']"
                    routerLinkActive="mlk-menu-link-active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    (click)="closeMenu()">
                    LES AMBASSADES MLK
                  </a>
                </li>
              }
            }
            <li class="nav-item">
              <a
                class="nav-link mlk-menu-link"
                [routerLink]="['/mlk/embassies/search']"
                routerLinkActive="mlk-menu-link-active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="closeMenu()">
                RECHERCHER UNE AMBASSADE
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link mlk-menu-link"
                [routerLink]="['/mlk/donation']"
                routerLinkActive="mlk-menu-link-active"
                [routerLinkActiveOptions]="{ exact: true }"
                (click)="closeMenu()">
                NOUS SOUTENIR
              </a>
            </li>
          </ul>
          <ul class="navbar-nav d-none d-md-flex justify-content-end">
            <li
              ngbDropdown
              class="nav-item position-relative"
              ngbTooltip="Mes notifications">
              <a
                [routerLink]="['/mlk/notifications']"
                routerLinkActive
                #notif="routerLinkActive"
                class="nav-link mlk-menu-link mlk-menu-link-icon">
                <i
                  class="bi mlk-text-dark-blue"
                  [ngClass]="{
                    'bi-bell': !notif.isActive,
                    'bi-bell-fill': notif.isActive,
                  }"></i>
              </a>
              @if (nbrNotifications() > 0) {
                <div class="mlk-notifications-indicator"></div>
              }
            </li>
            <li ngbDropdown class="nav-item" ngbTooltip="Mon profil">
              <a
                href
                [routerLink]="['/mlk/profile']"
                routerLinkActive
                #profile="routerLinkActive"
                class="nav-link mlk-menu-link mlk-menu-link-icon">
                <i
                  class="bi mlk-text-dark-blue"
                  [ngClass]="{
                    'bi-person': !profile.isActive,
                    'bi-person-fill': profile.isActive,
                  }"></i>
              </a>
            </li>
            <li ngbDropdown class="nav-item" ngbTooltip="Me déconnecter">
              <a
                href
                (click)="onLogout()"
                class="nav-link mlk-menu-link mlk-menu-link-icon">
                <i class="bi bi-power mlk-text-dark-blue"></i>
              </a>
            </li>
          </ul>
        </div>
      }
      @if (isAuthenticated() !== true) {
        <ul
          class="navbar-nav w-100 my-3 mt-md-0 d-flex flex-column flex-md-row gap-3 gap-md-0 justify-content-center justify-content-lg-end ms-auto mb-2 mb-lg-0">
          <li class="nav-item" style="margin-right: 3px">
            <a
              class="nav-link mlk-menu-link"
              [routerLink]="['']"
              routerLinkActive="mlk-menu-link-active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="closeMenu()"
              >ACCUEIL</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link mlk-menu-link"
              [routerLink]="['/embassies']"
              routerLinkActive="mlk-menu-link-active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="closeMenu()">
              RECHERCHER UNE AMBASSADE
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link mlk-menu-link"
              [routerLink]="['/donation']"
              routerLinkActive="mlk-menu-link-active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="closeMenu()">
              NOUS SOUTENIR
            </a>
          </li>
          <li class="nav-item" style="margin-right: 3px">
            <a
              class="nav-link mlk-menu-link"
              [routerLink]="['/login']"
              routerLinkActive="mlk-menu-link-active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="closeMenu()"
              >SE CONNECTER</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link mlk-menu-link"
              [routerLink]="['register']"
              routerLinkActive="mlk-menu-link-active"
              [routerLinkActiveOptions]="{ exact: true }"
              (click)="closeMenu()"
              >S'INSCRIRE
            </a>
          </li>
        </ul>
      }
    </div>
  </div>
</nav>
