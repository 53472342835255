<ng-template #content class="mlk-card" let-modal>
  <div
    class="mlk-bg-light-blue-gradient w-100 mlk-modal-gift-modal-header px-3 py-4 d-flex justify-content-between">
    <h3
      class="h3 fw-bold modal-title fw-bold mlk-text-dark-blue"
      id="modal-basic-title">
      {{ text() }}
    </h3>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      type="button"></button>
  </div>
  <div class="modal-body w-100">
    <div class="d-flex justify-content-evenly">
      <div class="d-none d-lg-block new-event-page-image-container"></div>
      <div class="d-flex flex-column gap-1">
        <form [formGroup]="eventForm">
          <h1 class="h1 mlk-text-dark-blue fw-bold">Votre événement</h1>
          <!--START  NOM EVENT -->
          <div class="form-group my-3">
            <label class="form-label fw-bold" for="name"
              >Nom de l'événement
              <span class="mlk-text-dark-grey fw-normal"> - requis</span>
            </label>
            <div class="input-group">
              <span class="input-group-text"><i class="bi bi-pen"></i></span>
              <input
                aria-label="name"
                class="form-control"
                formControlName="name"
                id="name"
                type="text" />
            </div>
            @if (name.invalid && (name.dirty || name.touched)) {
              <div class="text-danger">
                @if (name.getError('required')) {
                  <small> Vous devez renseigner le nom de l'événement </small>
                }
              </div>
            }
            @if (nameError(); as nameErrorValue) {
              <div class="text-danger">
                <small>{{ nameErrorValue }}</small>
              </div>
            }
          </div>
          <!--END NOM EVENT-->
          <!--START DESCRIPTION -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold" for="description"
              >Description de l'événement
            </label>
            <textarea
              aria-label="description"
              class="form-control"
              cols="50"
              formControlName="description"
              id="description"
              rows="4"></textarea>
            @if (descriptionError(); as descriptionError) {
              <div class="text-danger">
                <small>{{ descriptionError }}</small>
              </div>
            }
          </div>
          <!--END DESCRIPTION -->
          <!-- START DATE -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold" for="date"
              >Date de l'événement<span class="mlk-text-dark-grey fw-normal"
                >- requis</span
              >
            </label>
            <div class="input-group">
              <span class="input-group-text"
                ><i class="bi bi-calendar-week"></i
              ></span>
              <input
                #d="ngbDatepicker"
                [markDisabled]="isDisabled"
                [minDate]="minSelectableDate"
                class="form-control"
                formControlName="date"
                id="date"
                ngbDatepicker
                placeholder="yyyy-mm-dd" />
              <button
                (click)="d.toggle()"
                class="btn btn-outline-secondary bi bi-calendar3"
                type="button"></button>
            </div>
            @if (date.invalid && (date.dirty || date.touched)) {
              <div class="text-danger">
                @if (date.getError('required')) {
                  <small> Vous devez renseigner la date de l'événement </small>
                }
              </div>
            }
            @if (startOnError(); as startOnErrorValue) {
              <div class="text-danger">
                <small>{{ startOnErrorValue }}</small>
              </div>
            }
            @if (endOnError(); as endOnErrorValue) {
              <div class="text-danger">
                <small>{{ endOnErrorValue }}</small>
              </div>
            }
          </div>
          <!--END DATE -->
          <div
            class="d-flex flex-column flex-md-row gap-2 gap-lg-0 justify-content-md-between">
            <!--START FROM DATE-->
            <div class="form-group mb-3 mb-md-0">
              <label class="form-label fw-bold" for="from"
                >Heure de début
                <span class="mlk-text-dark-grey fw-normal">- requis</span>
              </label>
              <div class="input-group">
                <ngb-timepicker
                  formControlName="from"
                  id="from"></ngb-timepicker>
              </div>
            </div>
            <!--END FROM DATE-->
            <!--START TO DATE-->
            <div class="form-group">
              <label class="form-label fw-bold" for="to"
                >Heure de fin
                <span class="mlk-text-dark-grey fw-normal">- requis</span>
              </label>
              <div class="input-group">
                <ngb-timepicker formControlName="to" id="to"></ngb-timepicker>
              </div>
            </div>
            <!--END TO DATE-->
          </div>
          @if (fromHour.invalid && (fromHour.dirty || fromHour.touched)) {
            <div class="text-danger">
              @if (fromHour.getError('required')) {
                <small>
                  Vous devez renseigner l'heure de début de l'événement
                </small>
              }
            </div>
          }
          @if (toHour.invalid && (toHour.dirty || toHour.touched)) {
            <div class="text-danger">
              @if (toHour.getError('required')) {
                <small>
                  Vous devez renseigner l'heure de fin de l'événement
                </small>
              }
            </div>
          }
          @if (endOnError(); as endOnErrorValue) {
            <div class="text-danger">
              <small>{{ endOnErrorValue }}</small>
            </div>
          }
          @if (startOnError(); as startOnErrorValue) {
            <div class="text-danger">
              <small>{{ startOnErrorValue }}</small>
            </div>
          }
          @if (eventForm.getError('superiorThanAutorize')) {
            <div class="text-danger">
              <small>
                L'heure de début doit être plus petite que l'heure de fin
              </small>
            </div>
          }
          <button
            (click)="modal.close()"
            [disabled]="eventForm.invalid"
            class="btn btn-lg mlk-btn-blue-filled mt-5"
            style="width: 100%"
            type="submit">
            Enregistrer
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<button
  (click)="open(content)"
  class="btn mlk-btn-blue-filled"
  style="width: 100%">
  <div>{{ text() }}</div>
</button>
